import React, { useCallback } from 'react'
import About from './About'
import ContactUs from './ContactUs'
import Home from './Home'
import Products from './Products'
import VizSensor from 'react-visibility-sensor'

const Main = ({ setActivePage }) => {
  const VizSensorWrapper = useCallback(
    ({ children, page, ...props }) => (
      <VizSensor
        {...props}
        partialVisibility
        onChange={isVisible => {
          if (isVisible) setActivePage(page)
        }}
      >
        {children}
      </VizSensor>
    ),
    [setActivePage]
  )
  return (
    <>
      <VizSensorWrapper offset={{ top: 100 }} page="home">
        <Home />
      </VizSensorWrapper>
      <VizSensorWrapper offset={{ top: 100, bottom: 50 }} page="about">
        <About />
      </VizSensorWrapper>
      <VizSensorWrapper offset={{ top: 445, bottom: 50 }} page="products">
        <Products />
      </VizSensorWrapper>
      <VizSensorWrapper offset={{ top: 100, bottom: 100 }} page="contact">
        <ContactUs />
      </VizSensorWrapper>
    </>
  )
}

export default Main
