import React, { useLayoutEffect } from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import BakeryProducts from '../assets/bakeryproducts.jpeg'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import { Parallax, useController } from 'react-scroll-parallax'

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 40%;
  position: relative;
  overflow: hidden;

  .about-content {
    padding: 10%;
    background-color: var(--light-color);
    align-self: center;
    height: 100%;

    h2 {
      color: var(--dark-color);
    }

    p {
      color: var(--dark-color);
      text-indent: 30px;
      font-size: 20px;
    }
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.3);
  }

  .image-container {
    position: absolute;
    height: 100%;
    width: 40%;
    right: 0;
    overflow: hidden;
  }

  .image-container .parallax-inner {
    height: 100%;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;

    .image-container {
      position: unset;
      width: 100%;
      height: 370px;
    }

    .image-container .parallax-inner {
      height: 210%;
    }

    .image-container img {
      transform: scale(1.2);
      object-position: 0% 5%;
    }
  }

  @media screen and (max-width: 400px) {
    .image-container {
      height: 270px;
    }

    .image-container .parallax-inner {
      height: 170%;
    }

    .image-container img {
      transform: scale(1.2);
    }
  }
`
const About = () => {
  const parallaxController = useController().parallaxController
  useLayoutEffect(() => {
    const handler = () => {
      parallaxController.update()
    }
    handler()
    window.addEventListener('load', handler)
    return window.removeEventListener('load', handler)
  }, [parallaxController])
  return (
    <AboutContainer id="about-container">
      <div className={'about-content'}>
        <PageTitle title="About us" />
        <ScrollAnimationWrapper delay={200}>
          <p>
            Equipments are critical in creating brand awareness for a company as
            they define the quality of the end product in the market. End-user
            often identifies a company’s product through its quality & hygienic
            way of manufacturing. Equipments also minimize the labor & hence it
            becomes imperative for a company to invest in quality equipment.
            Umiya Bakery Machineries members are committed to providing
            high-quality equipments, supplies & services to bakeries, food
            courts, industrial kitchens & hotels on Pan India Level.
          </p>
        </ScrollAnimationWrapper>
        <br />
        <ScrollAnimationWrapper>
          <p>
            Encompassing all, yet diverse, Umiya Bakery Machineries is a single
            source of equipments. Our delivery model is guided by dedication to
            client satisfaction. Gives your organization greater flexibility,
            higher efficiency, and lower cost.
          </p>
        </ScrollAnimationWrapper>
      </div>
      <Parallax className={'image-container'} y={[-40, 40]}>
        <img src={BakeryProducts} alt="About us building" />
      </Parallax>
    </AboutContainer>
  )
}

export default About
