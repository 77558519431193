import React from 'react'
import styled from 'styled-components'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const PageTitleWrapper = styled.div`
  .horizontal-line {
    height: 7px;
    margin: 10px 0;
    margin-left: 20px;
    width: ${props => props.lineWidth};
    background-color: ${props => props.lineBackground};
  }

  @media (max-width: 730px) {
    margin-bottom: 50px;
  }
`

const PageTitle = ({ title, lineBackground, lineWidth }) => {
  return (
    <PageTitleWrapper lineBackground={lineBackground} lineWidth={lineWidth}>
      <ScrollAnimationWrapper duration={1}>
        <h2 className="title">{title}</h2>
      </ScrollAnimationWrapper>
      <ScrollAnimationWrapper delay={10} animateIn="animate__fadeInLeft">
        <div className="horizontal-line"></div>
      </ScrollAnimationWrapper>
    </PageTitleWrapper>
  )
}

PageTitle.defaultProps = {
  lineBackground: 'var(--primary-color)',
  lineWidth: '100px'
}

export default PageTitle
