import styled from 'styled-components'
import { PRODUCT_IMAGE_PLACEHOLDER } from '../utils/productDetailsProvider'
import { ModalWrapper } from './ModalWrapper'
import AliceCarousel from 'react-alice-carousel'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import 'react-alice-carousel/lib/alice-carousel.css'

const ProductModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;

  .images {
    flex: 1;
    width: 50%;
    height: 100%;
    position: relative;

    .alice-carousel,
    .alice-carousel > div:first-child,
    .alice-carousel > div:first-child div {
      height: 100%;
    }

    .alice-carousel__prev-btn,
    .alice-carousel__next-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      z-index: 1;
    }

    .alice-carousel__prev-btn {
      left: 0;
      text-align: left;
    }

    .alice-carousel__next-btn {
      right: 0;
      text-align: right;
    }

    .alice-carousel__stage-item {
      width: 100% !important;
    }

    .navigation-button {
      display: flex;
      width: 35px;
      padding: 10px;
      border-radius: 50%;
      background: var(--light-color-transperent);
      color: var(--dark-color);
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.right {
        margin-left: auto;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    flex: 1;
    height: 100%;
    position: relative;
    overflow: auto;

    .info-container {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    // &::before {
    //   content: '';
    //   height: 100%;
    //   width: 40px;
    //   position: absolute;
    //   top: 0;
    //   left: -40px;
    //   background: linear-gradient(
    //     270deg,
    //     var(--dark-color),
    //     var(--dark-color-transperent)
    //   );
    // }

    .details {
      margin: 30px 0;
      white-space: pre-line;
    }
  }

  @media (max-width: 780px) {
    flex-direction: column;

    .images {
      height: 50%;
      width: 100%;
    }

    .info {
      width: 100%;

      &::before {
        width: 100%;
        height: 40px;
        top: -40px;
        left: 0;
        background: linear-gradient(
          0deg,
          var(--dark-color),
          var(--dark-color-transperent)
        );
      }
    }
  }
`

export const ProductModal = ({ product, onClose, open = false }) => {
  const images = product?.images?.map((image, index) => (
    <img key={index} src={image} alt={product.name} data-value={index} />
  ))
  return (
    <ModalWrapper onClose={onClose} open={open}>
      <ProductModalContainer>
        <div className="images">
          {product?.images?.length > 1 ? (
            // Carousel
            <AliceCarousel
              animationDuration={500}
              autoPlayInterval={1500}
              infinite
              innerWidth="100%"
              disableDotsControls
              items={images}
              autoPlayStrategy={'all'}
              autoWidth
              renderPrevButton={({ isDisabled }) => (
                <div
                  className={`navigation-button left ${
                    isDisabled ? 'disabled' : ''
                  }`}
                >
                  <FaChevronLeft />
                </div>
              )}
              renderNextButton={({ isDisabled }) => (
                <div
                  className={`navigation-button right ${
                    isDisabled ? 'disabled' : ''
                  }`}
                >
                  <FaChevronRight />{' '}
                </div>
              )}
              // disableButtonsControls
            />
          ) : (
            // Singal image
            <img
              src={
                product?.images?.length > 0
                  ? product.images[0]
                  : PRODUCT_IMAGE_PLACEHOLDER
              }
              alt={product.name}
            />
          )}
        </div>
        <div className="info">
          <div className="info-container">
            <h2 className="name">{product.name}</h2>
            <p className="details">{product.details}</p>
            <p className="price">{product.price}</p>
            {product.detailsImage && (
              // <div className="detail-image">
              <img src={product.detailsImage} alt={product.name} />
              // </div>
            )}
          </div>
        </div>
      </ProductModalContainer>
    </ModalWrapper>
  )
}
