import React, { useState } from 'react'
import 'animate.css/animate.min.css'
import Navbar from './components/Navbar'
import Main from './Pages/Main'
import { ParallaxProvider } from 'react-scroll-parallax'
import Footer from './components/Footer'
import ThemeSwitcher from './components/ThemeSwitcher'

function App() {
  const [activePage, setActivePage] = useState('home')

  return (
    <>
      <ThemeSwitcher />
      <Navbar activePage={activePage} setActivePage={setActivePage} />
      <ParallaxProvider>
        <Main setActivePage={setActivePage} />
      </ParallaxProvider>
      <Footer />
    </>
  )
}

export default App
