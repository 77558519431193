import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const ScrollAnimationWrapper = ({
  children,
  delay,
  animateOnce,
  animateIn,
  duration,
  offset,
  style
}) => (
  <ScrollAnimation
    className={`delay-${delay}`}
    offset={offset}
    animateIn={animateIn}
    duration={duration}
    delay={delay}
    animateOnce={animateOnce}
    style={style}
  >
    {children}
  </ScrollAnimation>
)

ScrollAnimationWrapper.defaultProps = {
  delay: 0,
  animateOnce: false,
  animateIn: 'animate__fadeInUp',
  duration: 0.7,
  offset: 50,
  style: {}
}

export default ScrollAnimationWrapper
