import React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import { PRODUCT_IMAGE_PLACEHOLDER } from '../utils/productDetailsProvider'

const shakeHorizontal = keyframes`

0% {
  transform: translateX(0);
}

50% {
  transform: translateX(-10px);
}

100% {
  transform: translateX(0);
}
`

const ProductCardWrapper = styled.div`
  width: 27vw;
  min-width: 265px;
  position: relative;
  display: grid;
  grid-template-rows: 250px 1fr 40px;
  margin: 0px 15px;
  margin-bottom: 25px;
  background-color: var(--light-color);
  color: var(--dark-color);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 25px -5px #0000002b;

  .image-container {
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .details-container {
    position: relative;
    grid-row: 2;
    padding: 20px 15px;

    &::before {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: -40px;
      left: 0;
      background: linear-gradient(
        0deg,
        var(--light-color),
        var(--dark-color-transperent)
      );
    }

    h3 {
      font-size: 1.4em;
      margin-bottom: 5px;
    }

    p {
      display: -webkit-box;
      max-height: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
  }

  .button-container {
    cursor: pointer;
    border-top: 1px dashed;
    transition: all 0.2s ease;

    &:hover {
      background: var(--primary-color);
      color: var(--light-color);
      // border-color: var(--primary-color);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px;

      .arrow-icon {
        margin-left: 20px;
        animation: ${shakeHorizontal} 2s ease-in-out infinite;
      }
    }
  }

  @media (max-width: 780px) {
    width: 47vw;
  }

  @media (max-width: 730px) {
    width: 100%;
    margin-left: 0;
  }
`

const ProductCard = ({ product, onClickDetail }) => {
  return (
    <ProductCardWrapper>
      <div className="image-container">
        <img
          src={
            product?.images?.length > 0
              ? product?.images[0]
              : PRODUCT_IMAGE_PLACEHOLDER
          }
          alt={product.name}
        />
      </div>
      <div className="details-container">
        <h3>{product.name}</h3>
        <p>{product.details}</p>
      </div>
      <div className="button-container" onClick={() => onClickDetail(product)}>
        <span>
          View details <FaArrowRight className="arrow-icon" />
        </span>
      </div>
    </ProductCardWrapper>
  )
}

export default ProductCard
