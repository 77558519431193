import React from 'react'
import styled from 'styled-components'
import Background from '../assets/background.jpeg'

const HomeWrapper = styled.section`
  position: relative;
  height: 100vh;
  // background-color: var(--primary-color);
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  center {
    padding: 0 30px;

    h1 {
      font-size: 4rem;
    }
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.6;
      z-index: 1;
      background-image: repeating-linear-gradient(
          135deg,
          rgba(255, 255, 255, 0.04) 0px,
          rgba(255, 255, 255, 0.04) 2px,
          transparent 2px,
          transparent 4px
        ),
        linear-gradient(0deg, rgb(225, 42, 142), rgb(4, 2, 73));
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      filter: blur(5px);
    }
  }
`

const Home = () => {
  return (
    <HomeWrapper id="home-container">
      <div className="background-image">
        <img src={Background} alt="Umiya Bakery Machineries & Services" />
      </div>
      <center>
        <h1>We deliver</h1>
        <h1>What you truly deserve</h1>
      </center>
    </HomeWrapper>
  )
}

export default Home
