import { isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import ProductCard from '../components/ProductCard'
import { ProductModal } from '../components/ProductModal'
import ScrollAnimationWrapper from '../components/ScrollAnimationWrapper'
import { productDetailsProvider } from '../utils/productDetailsProvider'

const ProductsWrapper = styled.section`
  background-color: var(--dark-color);
  padding: 30px;
  color: var(--primary-color);

  .title {
    color: var(--light-color);
  }

  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 25px 0;
  }
`

const Products = () => {
  const [products, setProducts] = useState([])

  useEffect(() => {
    ;(async () => {
      const products = await productDetailsProvider()
      setProducts(products)
    })()
  }, [setProducts])

  const [selectedProduct, setSelectedProduct] = useState({})
  return (
    <ProductsWrapper id="products-container">
      <ProductModal
        product={selectedProduct}
        open={!isEmpty(selectedProduct)}
        onClose={() => {
          setSelectedProduct({})
        }}
      />
      <PageTitle
        title="Products"
        lineBackground={'var(--primary-color-light)'}
      />
      <div className="products-list">
        {products.map((product, index) => {
          const desired_count = Math.floor(window.innerWidth / 335) // 335 is approx width of the card
          const delay = (index % desired_count) * 150
          return (
            <ScrollAnimationWrapper
              delay={delay}
              style={{
                zIndex: index
              }}
              key={index}
            >
              <ProductCard
                product={product}
                onClickDetail={product => setSelectedProduct(product)}
              />
            </ScrollAnimationWrapper>
          )
        })}
      </div>
    </ProductsWrapper>
  )
}

export default Products
