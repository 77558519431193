import React from 'react'
import styled from 'styled-components'
import { Logo } from '../utils/logoProvider'
import ScrollAnimationWrapper from './ScrollAnimationWrapper'

const FooterWrapper = styled.footer`
  background-color: var(--dark-color);
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 3%;

    .logo-container {
      display: flex;

      img {
        width: 300px;
      }

      svg {
        width: 300px;
        height: auto;

        .upper-half-logo {
          fill: var(--primary-color);
          stroke: var(--primary-color);
        }

        .lower-half-logo {
          fill: var(--light-color);
          stroke: var(--light-color);
        }
      }

      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 20px;

        .upper {
          color: var(--primary-color);
          font-size: 30px;
        }
      }
    }

    .details-container {
      h5 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
      }

      a {
        text-decoration: none;
        color: var(--light-color);

        &:hover {
          text-decoration: underline;
        }
      }

      .main-office-container {
        display: flex;
        flex-direction: column;

        .address {
          max-width: 400px;
          align-self: center;
        }

        .contact-group {
          display: flex;
          justify-content: center;
          margin-top: 15px;
          flex-wrap: wrap;

          .contact:first-child {
            margin-right: 10px;

            &:after {
              content: '|';
              margin-left: 10px;
            }
          }
        }
      }

      .sub-office-container {
        margin-top: 20px;
        h5 {
          margin-bottom: unset;
        }

        .sub-office-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .sub-office {
          margin-top: 15px;
        }

        .sub-office:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }

  .credit {
    align-self: flex-end;
    overflow: hidden;
    padding: 0 10px 10px 0;
    font-size: 11px;
    letter-spacing: 1.7px;

    & > span:first-child {
      opacity: 0.6;
    }

    .love {
      color: var(--primary-color);
    }
    a {
      color: inherit;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 975px) {
    .content {
      flex-direction: column;

      .details-container {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 570px) {
    .content
      .details-container
      .main-office-container
      .contact-group
      .contact:first-child:after {
      content: '';
    }
  }

  @media screen and (max-width: 270px) {
    .content {
      .logo-container {
        flex-direction: column;
        justify-content: center;

        .name {
          align-items: center;
        }
      }
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <div className="content">
      <ScrollAnimationWrapper>
        <div className="logo-container">
          <div className="logo">
            <Logo />
          </div>
          <div className="name hidden">
            <span className="upper">Umiya</span>
            <span className="lower">BMS</span>
          </div>
        </div>
      </ScrollAnimationWrapper>

      <div className="details-container">
        <ScrollAnimationWrapper delay={50}>
          <div className="main-office-container">
            <h5>Main office</h5>
            <a href="https://goo.gl/maps/LngKFuUM5PNYr5He9" className="address">
              Shop No 2, Silver Palace,
              <br /> Near Fatima school, Subhash Nagar,
              <br /> Belvali, Badlapur (West)
              <br /> Maharstra: 421503
            </a>
            <div className="contact-group">
              <span className="contact">
                Hardik Chhabhaiya (Patel){' '}
                <a href="tel:9769966171">+91 9769966171</a>
              </span>
              <a href="tel:9819595712" className="contact">
                +91 9819595712
              </a>
            </div>
          </div>
        </ScrollAnimationWrapper>
      </div>
    </div>
    <div className="credit animate__animated animate__repeat-3 animate__flash">
      <span>
        Handcrafted with <span className="love">♥</span> by{' '}
      </span>
      <span>
        <a
          href="https://www.linkedin.com/in/viral-limbani-6bb265a3/"
          target="_blank"
          rel="noreferrer"
        >
          Viral Limbani
        </a>
      </span>
    </div>
  </FooterWrapper>
)

export default Footer
