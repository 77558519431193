import breadSlicer from '../assets/product_images/bread_slicer-min.jpg'
import breadSlicer1 from '../assets/product_images/bread_slicer_1-min.jpg'
import bunDivider from '../assets/product_images/bun_divider.jpeg'
import choclateTemparing from '../assets/product_images/choclate_temparing-min.jpg'
import choclateTemparing1 from '../assets/product_images/choclate_temparing_1-min.jpg'
import choclateTemparing2 from '../assets/product_images/choclate_temparing_2-min.jpg'
import conicalRounder1 from '../assets/product_images/conical_rounder_1.png'
import conicalRounder2 from '../assets/product_images/conical_rounder_2.png'
import convectionOven from '../assets/product_images/convection_oven-min.jpg'
import deckOven from '../assets/product_images/deck_oven-min.jpg'
import deckOven1 from '../assets/product_images/deck_oven_1-min.jpg'
import deckOven1D1TElectric from '../assets/product_images/deck_oven_1D_1T_electric-min.jpg'
import deckOven1D1TElectric1 from '../assets/product_images/deck_oven_1D_1T_electric_1-min.jpg'
import deckOven1D1TElectric2 from '../assets/product_images/deck_oven_1D_1T_electric_2-min.jpg'
import deckOven1D2TElectric from '../assets/product_images/deck_oven_1D_2T_electric-min.jpg'
import deckOven1D2TElectric1 from '../assets/product_images/deck_oven_1D_2T_electric_1-min.jpg'
import deckOven1D2TElectric2 from '../assets/product_images/deck_oven_1D_2T_electric_2-min.jpg'
import deckOven1D2TGas from '../assets/product_images/deck_oven_1D_2T_gas-min.jpg'
import deckOven1D2TGas1 from '../assets/product_images/deck_oven_1D_2T_gas_1-min.jpg'
import deckOven1D3TElectric from '../assets/product_images/deck_oven_1D_3T_electric-min.jpg'
import deckOven2 from '../assets/product_images/deck_oven_2-min.jpg'
import deckOven2D12TGas from '../assets/product_images/deck_oven_2D_12T_gas-min.jpg'
import deckOven2D6TElectric1 from '../assets/product_images/deck_oven_2D_6T_electric_1-min.jpg'
import deckOven2D6TGas from '../assets/product_images/deck_oven_2D_6T_gas-min.jpg'
import deckOven2D6TGas2 from '../assets/product_images/deck_oven_2D_6T_gas_2-min.jpg'
import deckOven3 from '../assets/product_images/deck_oven_3-min.jpg'
import deckOven3D12TGas from '../assets/product_images/deck_oven_3D_12T_gas-min.jpg'
import deckOven3D24TGas from '../assets/product_images/deck_oven_3D_24T_gas-min.jpg'
import deckOven3D9TGas from '../assets/product_images/deck_oven_3D_9T_gas-min.jpg'
import displayCounter from '../assets/product_images/display_counter-min.jpg'
import displayCounter1 from '../assets/product_images/display_counter_1-min.jpg'
import displayCounter2 from '../assets/product_images/display_counter_2-min.jpg'
import displayCounter3 from '../assets/product_images/display_counter_3-min.jpg'
import doughMoulder from '../assets/product_images/dough_moulder.png'
import doughMoulder1 from '../assets/product_images/dough_moulder_1.png'
import doughSheeter from '../assets/product_images/dough_sheeter-min.jpg'
import doughSheeter1 from '../assets/product_images/dough_sheeter_1-min.jpg'
import doughSheeter2 from '../assets/product_images/dough_sheeter_2-min.jpg'
import doughSheeter3 from '../assets/product_images/dough_sheeter_3-min.jpg'
import doughSheeter4 from '../assets/product_images/dough_sheeter_4-min.jpg'
import doughSheeter5 from '../assets/product_images/dough_sheeter_5-min.jpg'
import floorShifter from '../assets/product_images/floor_shifter-min.jpg'
import planetaryMixer from '../assets/product_images/planetary_mixer-min.jpg'
import planetaryMixer1 from '../assets/product_images/planetary_mixer_1-min.jpg'
import planetaryMixer2 from '../assets/product_images/planetary_mixer_2-min.jpg'
import planetaryMixer3 from '../assets/product_images/planetary_mixer_3-min.jpeg'
import planetaryMixer4 from '../assets/product_images/planetary_mixer_4-min.jpg'
import planetaryMixer7L from '../assets/product_images/planetary_mixer_7L-min.jpg'
import rotaryRackOven from '../assets/product_images/rotary_rack_oven-min.jpg'
import spiralMixer from '../assets/product_images/spiral_mixer-min.jpg'
import spiralMixer1 from '../assets/product_images/spiral_mixer_1-min.jpg'
import spiralMixer2 from '../assets/product_images/spiral_mixer_2-min.jpg'
import spiralMixer3 from '../assets/product_images/spiral_mixer_3-min.jpg'
import spiralMixer4 from '../assets/product_images/spiral_mixer_4-min.jpg'
import spiralMixer5 from '../assets/product_images/spiral_mixer_5-min.jpg'
import volumetricDivder2 from '../assets/product_images/volumetric_divder_2.png'
import volumetricDivider1 from '../assets/product_images/volumetric_divider_1.png'

import placeholder from '../assets/placeholder.jpeg'

export const productDetailsProvider = async () => {
  let products = [
    {
      name: 'Deck oven Gas or Electric',
      details: 'Made in Taiwan\n\n    Tray size 16 X 24',
      images: [
        deckOven3D9TGas,
        deckOven,
        deckOven1,
        deckOven1D1TElectric,
        deckOven1D1TElectric1,
        deckOven1D1TElectric2,
        deckOven1D2TElectric,
        deckOven1D2TElectric1,
        deckOven1D2TElectric2,
        deckOven1D2TGas,
        deckOven1D2TGas1,
        deckOven1D3TElectric,
        deckOven2,
        deckOven2D12TGas,
        deckOven2D6TElectric1,
        deckOven2D6TGas,
        deckOven2D6TGas2,
        deckOven3,
        deckOven3D12TGas,
        deckOven3D24TGas
      ],
      detailsImage: 'https://i.ibb.co/mRFFvB9/deck-oven-cat.png'
    },
    {
      name: 'Planetary mixer',
      details:
        'Made in Taiwan\n\n      Variants: 7,10,20,30,40,60,80 Liters Range',
      images: [
        planetaryMixer,
        planetaryMixer1,
        planetaryMixer2,
        planetaryMixer3,
        planetaryMixer4,
        planetaryMixer7L
      ],
      detailsImage: 'https://i.ibb.co/mSLCM7n/paneltary-mixer-cat.png'
    },
    {
      name: 'Spiral mixer',
      details:
        'Made in Taiwan\n      \n      Variants: 5,8,12,16,20,25,50,70,100 Kg Range',
      images: [
        spiralMixer,
        spiralMixer1,
        spiralMixer2,
        spiralMixer3,
        spiralMixer4,
        spiralMixer5
      ],
      detailsImage: 'https://i.ibb.co/1M264FK/spiral-mixer-cat.png'
    },
    {
      name: 'Bread slicer',
      details: 'Made in Taiwan\n      \n      Variants: 12 mm',
      images: [breadSlicer, breadSlicer1]
    },
    {
      name: 'Convection oven Gas or Electric',
      details: 'Made in Taiwan\n      \n      Tray size 16 X 24',
      images: [convectionOven],
      detailsImage: 'https://i.ibb.co/173yqR4/convection-oven-cat.png'
    },
    {
      name: 'Rotary oven',
      details:
        'As per the demand, we manufacture ourselves as well as we import from Tiwan or Turkey.\n      \n      Variant: Diesel or Gas',
      images: [rotaryRackOven],
      detailsImage: 'https://i.ibb.co/vCNW0Qv/rotary-oven-cat.png'
    },
    {
      name: 'Dough sheeter',
      details: 'Made in Taiwan',
      images: [
        doughSheeter3,
        doughSheeter,
        doughSheeter1,
        doughSheeter2,
        doughSheeter4,
        doughSheeter5
      ],
      detailsImage: 'https://i.ibb.co/5MhcjWD/sheeter-cat.png'
    },
    {
      name: 'Daugh moulder',
      details: 'Made in Turkey',
      images: [doughMoulder, doughMoulder1]
    },
    {
      name: 'Choclate temparing machine',
      details: '',
      images: [choclateTemparing, choclateTemparing1, choclateTemparing2]
    },
    {
      name: 'Cake display counter',
      details: '',
      images: [
        displayCounter2,
        displayCounter,
        displayCounter1,
        displayCounter3
      ]
    },
    {
      name: 'Floor shifter',
      details: '',
      images: [floorShifter]
    },
    {
      name: 'Bun divider',
      details: '',
      images: [bunDivider],
      detailsImage: 'https://i.ibb.co/fdbhXtc/bun-divider-cat.png'
    },
    {
      name: 'Conical rounder',
      details: '',
      images: [conicalRounder1, conicalRounder2],
      detailsImage: 'https://i.ibb.co/KmDz3SS/conical-rounder-cat.png'
    },
    {
      name: 'Volumetic divider',
      details: '',
      images: [volumetricDivder2, volumetricDivider1],
      detailsImage: 'https://i.ibb.co/L0ybYQF/volumetric-divider-cat.png'
    }
  ]

  return products
}

export const PRODUCT_IMAGE_PLACEHOLDER = placeholder
