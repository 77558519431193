import styled from 'styled-components'
import { FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'

const ModalWrapperContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1000;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  .background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--light-color);
    opacity: 0.6;
  }

  .close-icon {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 25px;
    color: var(--dark-color);
  }

  .content-container {
    min-width: 50%;
    height: 50%;
    background: var(--dark-color);
    border-radius: 5px;
    color: var(--light-color);
    max-width: 80%;
  }

  &.animate__fadeIn {
    z-index: 1000;
  }

  @media (max-width: 780px) {
    .content-container {
      height: 75%;
      min-width: 80%;
    }
  }

  @media (max-width: 570px) {
    .content-container {
      min-width: 90%;
    }
  }
`

export const ModalWrapper = ({
  children,
  onClose,
  open = false,
  showCloseButton = false,
  closeOnOutsideClick = true
}) => {
  const [_class, setClass] = useState('')

  useEffect(() => {
    if (open) {
      setClass('animate__fadeIn')
      document.getElementsByTagName('body')[0].classList.add('blur')
    } else if (!open && _class === 'animate__fadeIn') {
      setClass('animate__fadeOut')
      document.getElementsByTagName('body')[0].classList.remove('blur')
    }
  }, [open, _class])

  const toggleClose = () => {
    if (typeof onClose === 'function') {
      onClose(open)
    }
  }

  return (
    <ModalWrapperContainer className={`animate__animated ${_class} non-blur`}>
      <div
        className="background"
        onClick={() => {
          if (closeOnOutsideClick) {
            toggleClose()
          }
        }}
      ></div>
      <div className="close-icon" onClick={toggleClose}>
        <FaTimes />
      </div>
      <div className="content-container">{children}</div>
    </ModalWrapperContainer>
  )
}
